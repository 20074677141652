<template>
  <div class="template-pilot-list">
    <div>
      <template v-if="dataReady">
        <pilot-listing ref="pilotListing" :pilotsData="pilots" />
      </template>
    </div>
  </div>
</template>
<script>
import pilotListing from "@/components/subcomponents/pilot/pilotListing.vue";
import { GET_PILOTS } from "@/graphql/queries/queries";

const PILOTS_ATTRIBUTE = "pilots";

export default {
  name: "pilot",
  components: {
    pilotListing,
  },
  data() {
    return {
      pilots: [],
      error: null,
      dataReady: false,
    };
  },
  beforeDestroy: function () {
    this.$apollo.provider.defaultClient.cache.reset();
  },
  // mounted() {
  //   this.$apollo.queries.pilots.refetch();
  // },
  activated() {
    this.$apollo.queries.pilots.refetch();
  },
  apollo: {
    pilots: {
      // graphql query
      query: GET_PILOTS,
      update(data) {
        this.dataReady = true;
        return data[PILOTS_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/sass_files";
.template-pilot-list {
  padding-top: 24px;
}
</style>
